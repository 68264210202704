@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'thousand';
  src: url('./fonts/Thousand-Brands.otf') format('otf'),
    url('./fonts/Thousand-Brands.ttf') format('truetype');
}

body {
  color: #14213d;
  font-family: 'montserrat', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  color: white;
  background-color: #14213d;
  border-color: #14213d;
  border-radius: 20px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 0.875rem;
  font-weight: bold;
}

p {
  font-size: 0.875rem;
}

h1 {
  font-family: 'thousand', sans-serif;
  font-size: 3rem;
  line-height: 7vh;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 7vh;
}

h3 {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 7vh;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.6rem;
    line-height: 4vh;
    letter-spacing: 0.2vw;
  }

  h2 {
    font-size: 0.8rem;
  }

  h3 {
    font-size: 0.7rem;
  }
  
  p {
    font-size: 0.6rem;
  }
}

@media (max-width: 992px) and (min-width: 600px) {
  h1 {
    font-size: 1.5rem;
    line-height: 5vh;
  }

  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.8rem;
  }
  
  p {
    font-size: 0.7rem;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }
}