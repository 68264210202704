.contact-container {
    background-image: url("https://boac-website.s3.eu-central-1.amazonaws.com/contact_bg.png");
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100vw;
    margin-top: 10vh;
}

.contact-form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 25px;
    border-radius: 10px;
    max-width: 500px;
}

.contact-label {
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
}

.contact-input {
    border: 1px solid black;
    border-radius: 10px;
    background-color: #FBF7F7;
    padding: 10px;
}

.contact-submit-button {
    margin-top: 30px;
    padding: 5px 50px;
}

@media (max-width: 600px) {
    .contact-container h1 {
        font-size: 2rem;
    }

    .contact-container p {
        font-size: 0.6rem;
    }
}

@media (max-width: 992px) and (min-width: 600px) {
    .contact-container h1 {
        font-size: 2rem;
    }

    .contact-container p {
        font-size: 0.6rem;
    }
}