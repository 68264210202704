.animation-container {
  position: relative;
  left: 20%;
  width: 240px;
  height: 480px;
  background-color: white;
  border: 0px solid white;
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  border-right: 0;
  z-index: 4;
  overflow: hidden;
}

.photo-animate-col {
  position: relative;
  left: 200px;
}

.animation {
  position: absolute;
  width: 240px;
  bottom: 0;
  height: 0;
  background-color: #14213d;
  z-index: 1;
  animation: fill 20s linear infinite;
}

.text-container {
  position: absolute;
  top: 0%;
  left: 21.3%;
  width: 240px;
  height: 480px;
  border: 2px solid lightgray;
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  border-right: 0;
  opacity: 4;
  z-index: 5;
  background-color: white;
}

.Dot {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  border: 1px solid black;
  background-color: white;
  position: absolute;
  visibility: visible;
  z-index: 6;
  transition: background-color 0.5s;
  cursor: pointer;
}

.text1 {
  position: absolute;
  top: -50px;
  left: 90%;
  overflow: hidden;
  cursor: pointer;
}

.text2 {
  position: absolute;
  top: 70px;
  left: -30%;
  cursor: pointer;
}

.text3 {
  position: absolute;
  top: 150px;
  left: -43%;
  cursor: pointer;
}

.text4 {
  position: absolute;
  top: 235px;
  left: -45%;
  cursor: pointer;
}

.text5 {
  position: absolute;
  top: 320px;
  left: -65%;
  cursor: pointer;
}

.text6 {
  position: absolute;
  top: 400px;
  left: -43%;
  cursor: pointer;
}

.text7 {
  position: absolute;
  top: 485px;
  left: 80%;
  cursor: pointer;
}

.Dot1 {
  top: -7px;
  left: 99%;
  cursor: pointer;
}

.Dot2 {
  top: 80px;
  left: 20%;
  cursor: pointer;
}

.Dot3 {
  top: 160px;
  left: 2%;
  cursor: pointer;
}

.Dot4 {
  top: 240px;
  left: -3%;
  cursor: pointer;
}

.Dot5 {
  top: 320px;
  left: 4%;
  cursor: pointer;
}

.Dot6 {
  top: 400px;
  left: 25%;
  cursor: pointer;
}

.Dot7 {
  top: 472px;
  left: 96%;
  cursor: pointer;
}

.Dot.active {
  background-color: white;
}

.location-icon {
  margin-top: -15px;
  margin-left: -5px;
} 

@keyframes rotate360 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@keyframes fill {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

.bg-daire{
  position: absolute;
  right: -10%;
  top: 15%;
  width:320px;
  height:320px;
  background-color: #14213d;
  border-radius: 300px;
  z-index: 9;

 }
 .bg-daire-inner{
  position: relative;
  top:50px;
  left:50px;
  z-index: 9;
  width:220px;
  height: 220px;
  background-color: white;
  border-radius: 400px;
 }

.pictures {
  visibility: visible;
  z-index: 10;
  position: absolute;
  right: 20%;
  width: 440px;
  height: 440px;
  margin-right: -4vw;
  margin-top: 20px;
  background-color: transparent;
  border-radius: 230px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://boac-website.s3.eu-central-1.amazonaws.com/homepage_dessert_yabanmersini.png");
  transition: 0.4s opacity ease, 0.8s transform ease;
  transform: rotate(360deg);
  opacity: 0;
  
}

.rotate {
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.8s opacity ease;
  animation-name: example;
  animation-duration: 0.7s;
}

@keyframes example {
  0% {
    -webkit-transform: rotate(0deg);
    filter: blur(0px);
  }

  20% {
    filter: blur(0px);
  }

  40% {
    filter: blur(1px);
  }

  60% {
    filter: blur(1px);
  }

  80% {
    filter: blur(0px);
  }
  
  100% {
    -webkit-transform: rotate(360deg);
    filter: blur(0px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .photo-animate-col {
    left: 100px;
  }

  .fade-in {
    right: 0 !important;
  }

  .pictures {
    width: 300px;
    height: 300px;
    top: 8vh;
    right: 0;
  }

  .bg-daire {
    display: none;
  }

  .photo-animate-col {
    left: 50px;
    margin-top: 50px;
  }

  .text-container {
    left: 22%;
  }

  .text1 {
    top: -40px;
    left: 90%;
  }
  
  .text2 {
    left: -14%;
    top: 75px;
  }
  
  .text3 {
    left: -29%;
    top: 155px;
  }
  
  .text4 {
    left: -32%;
    top: 238px;
  }
  
  .text5 {
    left: -42%;
  }
  
  .text6 {
    left: -21%;
  }

  .text7 {
    left: 80%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (max-width: 768px) and (min-width: 600px) {
  .photo-animate-col {
    left: 50px;
  }

  .pictures {
    width: 340px;
    height: 340px;
    top: 6vh;
    right: 10vw;
  }

  .bg-daire {
    top: 15%;
    right: -15vw;
  }
  
  .text-container {
    left: 21.5%;
  }

  .text1 {
    left: 90%;
  }
  
  .text2 {
    left: -20%;
  }
  
  .text3 {
    left: -34%;
  }
  
  .text4 {
    left: -37%;
  }
  
  .text5 {
    left: -50%;
  }
  
  .text6 {
    left: -30%;
  }

  .text7 {
    left: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 992px) and (min-width: 768px) {
  .pictures {
    width: 340px;
    height: 340px;
    top: 6vh;
    right: 0;
  }

  .bg-daire {
    top: 15%;
  }

  .text-container {
    left: 22%;
  }

  .photo-animate-col {
    left: 100px;
  }

  .text1 {
    left: 90%;
  }
  
  .text2 {
    left: -20%;
  }
  
  .text3 {
    left: -33%;
  }
  
  .text4 {
    left: -37%;
  }
  
  .text5 {
    left: -50%;
  }
  
  .text6 {
    left: -30%;
  }

  .text7 {
    left: 80%;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .pictures {
    width: 320px;
    height: 320px;
    top: 6vh;
    margin-right: -7vw;
  }

  .text-container {
    left: 22%;
  }

  .bg-daire {
    top: 15%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (max-width: 1400px) and (min-width: 1200px) {
  .pictures {
    width: 360px;
    height: 360px;
    top: 3vh;
    margin-right: -7vw;
  }
}