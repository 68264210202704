.img-pull-left-side:hover {

    transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    /* IE 9 */
    -webkit-transform: rotate(-15deg);
    transition: transform 0.5s ease-in-out;
    z-index: -1;
}


@media (max-width: 600px) {
    .products-container {
        margin-bottom: -50px;
    }
}