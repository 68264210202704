.about-container {
    margin-top: 10vh;
}

.about-container h3 {
    margin: 0;
}

.about-bg-img-1 {
    width: 80%;
    margin-left: 50px;
}

.about-bg-img-2 {
    width: 80%;
    margin-top: -50px;
}

.about-bg-full-img {
    display: block;
    width: 100%;
    height: auto;
}

.about-bg-text-1 {
    position: absolute;
    top: 5vh;
    left: 15vw;
    color: white;
    text-align: left;
}

.about-bg-text-1 p {
    width: 40vw;
}

.about-bg-text-2 {
    position: absolute;
    top: 0vh;
    right: 15vw;
    color: white;
    text-align: right;
}

.about-bg-text-2 p {
    width: 40vw;
}

.about-bg-col {
    position: relative;
    margin-top: 50px;
}

.about-first-text p {
    margin-top: 20px;
    padding: 0 10vw;
}

.about-second-text {
    text-align: left;
    margin-top: 100px;
    padding: 0 7vw;
}

.about-second-text h2 {
    font-weight: 400;
    margin-top: -12px;
}

.about-no-img {
    width: 30%;
    margin-top: 2vh;
}

.about-no-text {
    text-align: left;
    margin-left: -5vw;
    margin-top: 2vh;
}

.about-no-text p {
    margin-top: -10px;
    font-size: 0,8125rem;
}

.about-bg-full-col {
    position: relative;
    text-align: center;
}

.about-bg-full-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    padding: 0;
    width: 70vw;
    line-height: 4vh;
    font-weight: normal;
    font-size: 1.4rem;
}

.about-no-row {
    margin-top: 5vh;
}

.carousel-right-icon {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.carousel-left-icon {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

@media (max-width: 600px) {
    .about-container {
        margin-top: 5vh;
    }

    .about-bg-img-1 {
        width: 100%;
        margin-left: 15px;
    }

    .about-bg-img-2 {
        width: 100%;
        margin-top: -10px;
    }

    .about-bg-text-1 {
        top: 1vh;
        left: 15vw;
    }

    .about-bg-text-1 p {
        width:55vw;
        margin-top: -0.5vh;
        font-size: 0.4rem;
    }

    .about-bg-text-2 {
        top: -0.5vh;
        right: 10vw;
    }

    .about-bg-text-2 p {
        width:60vw;
        margin-top: -1vh;
        font-size: 0.4rem;
    }

    .about-bg-col {
        margin-top: 25px;
    }

    .about-first-text p {
        margin-top: 0;
    }

    .about-no-img {
        width: 50%;
    }

    .about-no-text {
        margin-top: 0;
    }

    .about-no-text h3 {
        line-height: 2vh;
        margin-top: 10px;
    }

    .about-no-text p {
        line-height: 1.5vh;
        margin-top: 8px;
        font-size: 0.5rem;
    }

    .about-second-text {
        margin-top: 50px;
    }

    .about-second-text h2 {
        margin-top: -30px;
    }

    .about-no-row {
        margin-top: 0;
    }

    .about-bg-full-text {
        font-size: 0.5rem;
        line-height: 1.2vh;
    }
}

@media (max-width: 992px) and (min-width: 600px) {
    .about-bg-text-1 {
        top: 1vh;
        left: 15vw;
    }

    .about-bg-text-2 {
        top: -5vh;
        right: 12vw;
    }

    .about-bg-text-1 p {
        margin-top: -2vh;
        width: 35vw;
    }

    .about-bg-text-2 p {
        margin-top: -3vh;
        width: 40vw;
    }

    .about-no-img {
        width: 40%;
    }

    .about-no-text {
        margin-top: 1vh;
    }

    .about-second-text {
        margin-top: 40px;
    }

    .about-second-text h2 {
        margin-top: -30px;
    }

    .about-no-row {
        margin-top: 0;
    }

    .about-bg-full-text {
        font-size: 1rem;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .about-bg-text-1 {
        top: 2vh;
    }

    .about-bg-text-2 {
        top: -6vh;
    }
}