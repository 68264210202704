.franchise-bg {
    display: block;
    width: 100%;
    height: auto;
}

.franchise-bg-full-col {
    position: relative;
    text-align: center;
    margin: 10vh 0;
}

.franchise-bg-full-form{
    position: relative;
    text-align: center;
}

.franchise-form-carousel {
    position: relative;
    display: flex;
    color: white;
    width: 100%;
}

.franchise-form-carousel > div {
    width: 20%;
    opacity: 10%;
}

.active {
    opacity: 100% !important;
}

.lines {
    width: 80%;
    position: relative;
    background-color: white;
    padding: 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 2vh;
}

.line-container {
    position: relative;
    width: 100%;
}

.horizontal-line {
    opacity: 100%;
    border: none;
    border-top: 2px solid #14213d;
    height: 1px;
    margin: 0;
    position: absolute;
    width: 100%;
}

.point {
    position: absolute;
    top: -1px;
    font-size: 30px;
    transform: translateY(-50%);
}

.point1 {
    left: -2px;
}

.point2 {
    left: 25%;
}

.point3 {
    left: 50%;
}

.point4 {
    left: 75%;
}

.point5 {
    left: 99.5%;
}

.franchise-form-header {
    position: relative;
    color: white;
    text-align: center;
    width: 100%;
    padding-top: 5vh;
}

.franchise-form-row {
    margin-top: 10vh;
}

.franchise-form { 
    position: relative;
    color: white;
    margin: 0 auto;
    padding: 25px;
    max-width: 500px;
    text-align: left;
}

.franchise-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid transparent;
    height: 40px;
}

.franchise-submit-button {
    margin-top: 50px;
    padding: 5px 50px;
    background-color: white;
    color: #14213d;
}

.visible {
    display: flex;
    flex-direction: column;
}

.invisible {
    display: none;
}

@media (max-width: 600px) {
    .franchise-bg-full-col {
        margin: 2vh 0;
    }

    .franchise-bg-full-col h1 {
        font-size: 13px;
    }
}

input[type="radio"] {
    -webkit-appearance: none; /* Safari ve Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Diğer tarayıcılar */
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
    background: white;
  }
  
  input[type="radio"]:checked::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background:blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }