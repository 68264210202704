.location-container {
    background-image: url("https://boac-website.s3.eu-central-1.amazonaws.com/contact_bg.png");
    height: 80vh;
    background-attachment: local;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100vw;
    margin-top: 10vh;
    min-height: 600px;
}

.location-container .google-map {
    width: 1000px;
    height: 450px;
}

@media (max-width: 600px) {
    .location-container .google-map {
        width: 300px;
    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .location-container .google-map {
        width: 600px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media (max-width: 992px) and (min-width: 768px) {
    .location-container .google-map {
        width: 700px;
    }
  }
  
  @media (max-width: 1200px) and (min-width: 992px) {
    .location-container .google-map {
        width: 800px;
    }
  }