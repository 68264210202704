.home-container {
    padding: 7vh 10vw;
    max-width: 100vw;
    overflow: hidden;
}

.home-entry {
    padding-left: 40px;
}

.home-entry p {
    margin-top: 5vh;
    max-width: 30vw;
    text-align: left;
}

.home-entry h3 {
    text-align: left;
}

.home-entry h1 {
    text-align: left;
}

.home-entry div {
    margin-top: 5vh;
    text-align: left;
}

.ozgun-tasarim-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: -20vh 0 5vh 0;
}

.ozgun-tasarim-img-1 {
    width: 110%;
    margin-top: 30vh;
    margin-left: 5vw;
}

.ozgun-tasarim-img-2 {
    width: 100%;
    margin-right: 10vh;
}

.carousel-control-icon {
    background-color: #14213d;
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 7px;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1;
}

.gift-design{
    width: 100%;
    height:100vh;
    background-image: url("https://boac-website.s3.eu-central-1.amazonaws.com/Frame_triangle.png");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: contain; 
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: left;
       
}

.gift-design h1{
    font-size: 70px;
}

.gift-design img{
    width: 60%;
}

.comments-carousel {
    padding: 0 80px;
}

.comments-carousel h1 {
    font-size: 20px;
}

@media (max-width: 992px) and (min-width: 600px) {
    .ozgun-tasarim-row {
        height: auto;
    }

    .ozgun-tasarim-img-1 {
        width: 80%;
        margin: 0;
    }
    
    .ozgun-tasarim-img-2 {
        width:80%;
        margin-right: 5vw;
        margin-top: -5vh;
    }

    .gift-design h1{
        font-size: 50px;
    }

    .gift-design img{
        width: 80%;
    }
    
    .home-entry p {
        margin-top: 3vh;
    }
    
    .home-entry div {
        margin-top: 3vh;
    }
}

@media (max-width: 600px) {
    .home-entry {
        padding: 0 30px;
        margin-bottom: 30px;
    }

    .home-entry .button {
        text-align: center;
    }

    .home-entry p {
        text-align: center;
        max-width: none;
    }
    
    .home-entry h3 {
        text-align: center;
        font-size: 0.8rem;
    }
    
    .home-entry h1 {
        text-align: center;
    }

    .home-container {
        padding-top: 1vh;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .home-entry p {
        margin-top: 20px;
    }

    .home-entry h3 {
        margin:0;
    }

    .home-entry div {
        margin-top: 20px;
    }

    .ozgun-tasarim-row {
        height: auto;
        margin-top: 3vh;
    }

    .ozgun-tasarim-img-1 {
        width: 70%;
        margin: 0;
    }
    
    .ozgun-tasarim-img-2 {
        width:90%;
        margin-right: 5vw;
        margin-top: -5vh;
    }

    .gift-design{
        width: 100%;
        height:40vh;
        background-image: url("https://boac-website.s3.eu-central-1.amazonaws.com/Frame_triangle.png");
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: contain; 
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-top: 50px;
           
    }

    .gift-design h1{
        font-size: 30px;
    }

    .gift-design img{
        width: 100%;
    }

    .comments-carousel h1 {
        font-size: 10px;
    }

    .comments-carousel {
        padding: 0 20px;
        margin-bottom: -30px;
    }
    
    .comments-carousel  .carousel-control-icon {
        width: 25px;
        height: 25px;
        padding: 3px;
        margin-top: -10px;
    }
}